export const Assets = [
    {
      logo:"https://cdncloud.cutedressup.in/playcutegames/logo/logo.png",
      logo2:"https://cdncloud.cutedressup.in/playcutegames/logo/privacy-logo.png",
      logo_16: "https://cdncloud.cutedressup.in/playcutegames/logo/favicon-16x16.svg",
      logo_32: "https://cdncloud.cutedressup.in/playcutegames/logo/favicon-32x32.svg",
      logo_192: "https://cdncloud.cutedressup.in/playcutegames/logo/favicon-192x192.svg",
      logo_270: "https://cdncloud.cutedressup.in/playcutegames/logo/favicon-270x270.svg",
      logo_512: "https://cdncloud.cutedressup.in/playcutegames/logo/favicon-512x512.svg",
      logo_head: "/assets/logo/logo-head.png",
      home: "https://cdncloud.cutedressup.in/playcutegames/logo/home.svg",
      drop_down_icon: "https://cdncloud.cutedressup.in/playcutegames/logo/drop_down.svg",
      // Cate
      searchIcon: "https://cdncloud.cutedressup.in/playcutegames/items/search.svg",
      exclusiveIcon: "https://cdn.cutedressup.in/games/dropdown/cutedressupgames.png",
      princessIcon: "https://cdn.cutedressup.in/games/dropdown/princess.png",
      dressUpGames: "https://cdn.cutedressup.in/games/dropdown/dress.png",
      makeoverGames: "https://cdn.cutedressup.in/games/dropdown/make-up.png",
      decorationGames: "https://cdn.cutedressup.in/games/dropdown/decorations.png",
      weddingGames: "https://cdn.cutedressup.in/games/dropdown/wedding.png",
      celebrityGames: "https://cdn.cutedressup.in/games/dropdown/celebrity.png",
      cookingGames: "https://cdn.cutedressup.in/games/dropdown/cooking.png",
      doctorGames: "https://cdn.cutedressup.in/games/dropdown/doctor.png",
      // search
      s_icon: "https://cdncloud.cutedressup.in/playcutegames/logo/search.svg",
      c_icon: "https://cdncloud.cutedressup.in/playcutegames/logo/sclose.svg",
      f_icon: "https://cdncloud.cutedressup.in/playcutegames/items/full-screen.svg",
      mback_icon: "https://cdncloud.cutedressup.in/playcutegames/items/back.svg",
      //new lablel
      new_l: "https://cdncloud.cutedressup.in/playcutegames/items/new3.svg",
      // m_play
      m_play_btn: "https://cdncloud.cutedressup.in/playcutegames/items/play.svg",
      //Parters
      cutedressup:"https://cdn.cutedressup.in/games/dropdown/grid-logo.png",
      dl_girls:"https://cdn.cutedressup.in/games/cutedressup_site/dl-girls.png",
      friv_games:"https://cdn.cutedressup.in/games/cutedressup_site/frivgames.png",
      brightest_games:"https://cdn.cutedressup.in/games/cutedressup_site/brightestgames.png",
      vitality_games:"https://cdn.cutedressup.in/games/cutedressup_site/vitalitygames.png",
      dressupwho:"/assets/our-friends/dressupwho.png",
      image404:"/assets/logo/404.svg",
      logo_header:"/assets/logo/logo.png",
      navicon:"/assets/nav/navicon.png",
      nav_S_icon: "/assets/nav/search.svg",
      nav_Clear_icon: "/assets/nav/close.svg",
    }
  ];