import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import styles from '../../styles/Layout.module.css';
import Spinner from '../../src/components/Spinner'; // Adjust the import path as needed

const Layout = ({ children }) => {
    const [isTransitioning, setIsTransitioning] = useState(false);
    const router = useRouter();
  
    useEffect(() => {
      const handleRouteChangeStart = () => {
        setIsTransitioning(true);
      };
  
      const handleRouteChangeComplete = () => {
        setIsTransitioning(false);
      };
  
      router.events.on('routeChangeStart', handleRouteChangeStart);
      router.events.on('routeChangeComplete', handleRouteChangeComplete);
  
      return () => {
        router.events.off('routeChangeStart', handleRouteChangeStart);
        router.events.off('routeChangeComplete', handleRouteChangeComplete);
      };
    }, [router.events]);
  
    return (
        <>
        {isTransitioning && <Spinner />} 
        <div className={`${styles.page} ${isTransitioning ? styles.pageTransition : styles.pageVisible}`}>
        {children}
      </div>
        </>
      
    );
  };
  
  export default Layout;