import Link from 'next/link';
import styles from '../../styles/Navbar.module.css';
import { Assets } from '../../src/list/Assets';
import { useState } from 'react';
import { GameList } from '../../src/list/GameList'; // Import the GameList
import SideNavbar from '../components/SideNavbar.js'

const Navbar = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGames, setFilteredGames] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  
  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    
    if (term.trim() !== '') {
      // Send search term to Google Analytics
      window.gtag('event', 'search', {
        search_term: term,
      });
      // Add your search submit logic here
      setShowResults(true);
    } 

    if (term) {
      // Filter games based on search term
      const results = GameList.filter(game =>
        game.title.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredGames(results);
      setShowResults(true);
      setNoResults(results.length === 0);
    } else {
      setShowResults(false);
      setNoResults(false);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  
  };

  const handleCloseResults = () => {
    setShowResults(false);
  };

  const handleClearSearch = () => {
    setSearchTerm(''); // Clear the input field
    setShowResults(false); // Optionally close the search results
  };

  const handleClearSearch_2 = () => {
    setTimeout(() => {
      handleClearSearch();
    }, 500); 
  };


  return (
    <>
    <nav className={styles.navbar}>
      
      <div className={styles.logo}>
      <div className={`${styles.Nav_icon} ${isSidebarOpen ? styles.rotated : ''}`} onClick={toggleSidebar}>
      
          <img src={Assets[0].navicon} alt={"Logo"}  />
          
            </div>

        <Link href="/">
          <a><div>
          <img src={Assets[0].logo_header} alt={"Logo"} className={styles.img_logo}  />
            </div></a>
        </Link>

          
      </div>
      
      
      <form onSubmit={handleSearchSubmit} className={styles.searchForm}>
      <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className={styles.searchInput}
        />
        {showResults&&(<div className={styles.Close_icon} onClick={handleClearSearch}> 
        <img src={Assets[0].nav_Clear_icon} alt={"Close Icon"} className={styles.s_icon} />
        </div>)}

        {!showResults&&(<div className={styles.Search_icon} >
        <img src={Assets[0].nav_S_icon} alt={"Search Icon"} className={styles.s_icon} />
        </div>)}
        
        {/* <button type="submit" className={styles.searchButton}>Search</button> */}
      </form>
      {showResults && !noResults && (
        <>
        <div className={styles.overlay} onClick={handleClearSearch} ></div>
        <div className={styles.Results_Container} >
           <div className={styles.Results_Padding} ></div>
        <div className={styles.searchResults} >
          {/* <div className={styles.closeContainer}><button className={styles.closeButton} onClick={handleClearSearch}>✖</button></div> */}
          <div className={styles.Results_Holder} onClick={handleClearSearch_2}>
           
          {filteredGames.map((game, index) => (
             <Link href={game.path[0]}>
              
              <a className={styles.A_tag}>
            <div key={index} className={styles.searchResultItem}>
              <img src={game.thumb} alt={game.title} className={styles.resultImage} />
              <span>{game.name[0]}</span>
            </div>
            </a>
            </Link>
          ))}
          </div>
        </div>
        <div className={styles.Results_Padding} onClick={handleCloseResults}></div>
       
        </div>
        </>
      )}

{showResults && noResults && (
   <><div className={styles.overlay} onClick={handleClearSearch} ></div>
    <div className={styles.Results_Container} >
          <div className={styles.Results_Padding} ></div>
        <div className={styles.searchResults} >
          
          <div onClick={handleClearSearch_2}>
          
              
              <a className={styles.A_tag}>
            <div className={styles.searchResultItem}>
              
              <span>Game not Found</span>
            </div>
            </a>
            
          </div>
        </div>
        <div className={styles.Results_Padding} onClick={handleCloseResults}></div>
       
        </div>
        
        </>
      )}
      <div></div>

      {/* <ul className={styles.navLinks}>
        <li>
          <Link href="/games">
            <a>Games</a>
          </Link>
        </li>
        <li>
          <Link href="/categories">
            <a>Categories</a>
          </Link>
        </li>
        <li>
          <Link href="/about">
            <a>About</a>
          </Link>
        </li>
      </ul> */}

    </nav>
    <SideNavbar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
    
    </>
  );
};
// 

export default Navbar;
